export const INITIAL = 'INITIAL'
export const INITIAL_WITH_DATA = 'INITIAL_WITH_DATA'
export const CALCULATE_FEE_STEP = 'CALCULATE_FEE_STEP'
export const TRANSFER_INSTRUCTIONS_STEP = 'TRANSFER_INSTRUCTIONS_STEP'
export const SET_ACC_SYMBOL = 'SET_ACC_SYMBOL'
export const SET_EVM_SYMBOL = 'SET_EVM_SYMBOL'
export const SET_MINT_AMOUNT_AND_RECEIVED = 'SET_MINT_AMOUNT_AND_RECEIVED'
export const UPDATE_MINT_DESTINATION_ADDRESS = 'UPDATE_MINT_DESTINATION_ADDRESS'
export const GET_FEES = 'GET_FEES'
export const GET_TOKENS = 'GET_TOKENS'
export const UPDATE_EVM_ADDRESS = 'UPDATE_EVM_ADDRESS'
export const SET_GLOBAL_NETWORK_ERROR = 'SET_GLOBAL_NETWORK_ERROR'
export const SET_GLOBAL_SERVER_NOT_RESPONDED = 'SET_GLOBAL_SERVER_NOT_RESPONDED'